.Profile-Card {
    background-color: $white;
    border-radius: $border-radius-small;
    padding: 20px;
    margin-bottom: 30px;

    &__title {
        width: 100%;
        background-color: $primary-color;
        color: $white;
        font-size: 1rem;
        line-height: 1.5;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        padding: 1rem;
        display: flex;
        align-items: center;

        span {
            font-size: 22px;
            margin-right: 10px;
        }
    }
    .userProfile{
        &__Contacts{
            display: flex;
            height: 100%;
            justify-content: center;
            flex-direction: column;
            @media screen and (max-width: 992px) {
                margin-top: 8px;
            }
            @media screen and (max-width: 768px) {
                align-items: center;
                margin-top: 0px;
                div{
                    text-align: center;
                    margin-bottom: 5px;
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        button{
            &.Follow__User{
                margin-bottom: 8px;
            }
        }
    }
}

.InviteContest {
    a {
        background-color: $purple;
        border: 2px solid $purple;
        color: $white !important;
        border-radius: 25px;
        padding: 3px 10px;
        transition: all 0.3s;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        cursor: pointer;

        &:hover {
            background-color: transparent;
            color: $purple !important;
        }
    }
}

.FollowUser {
    width: 100%;

    a {
        background-color: $primary-color;
        border: 2px solid $primary-color;
        color: $white !important;
        border-radius: 25px;
        padding: 3px 10px;
        transition: all 0.3s;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 5px;
        cursor: pointer;

        &:hover {
            background-color: transparent;
            color: $primary-color !important;
        }

        i {
            margin-right: 5px;
            font-size: 15px;
        }
    }
}

.DeleteItem {
    width: 100%;

    a {
        background-color: $red;
        border: 2px solid $red;
        color: $white !important;
        border-radius: 25px;
        padding: 3px 10px;
        transition: all 0.3s;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 5px;
        cursor: pointer;

        &:hover {
            background-color: transparent;
            color: $red !important;
        }

        i {
            margin-right: 5px;
            font-size: 15px;
        }
    }
}

.InviteContest {
    width: 100%;

    a {
        i {
            margin-right: 5px;
            font-size: 15px;
        }
    }
}

.show {
    .HireMe {
        background-color: transparent !important;
        color: $secondary-color;
        border-color: $secondary-color !important;
        color: $secondary-color !important;

        span {
            i {
                font-size: 7px;
            }

            &::before {
                border: 1px dashed $secondary-color;
            }
        }
    }

    .dropdown-menu {
        text-align: center;
    }
}

.HireMe {
    background-color: $secondary-color !important;
    border: 2px solid $secondary-color;
    color: $white !important;
    border-radius: 25px;
    padding: 3px 10px;
    transition: all 0.3s;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
    position: relative;

    &:hover {
        background-color: transparent !important;
        color: $secondary-color !important;
        border-color: $secondary-color !important;

        a {
            color: $secondary-color;
        }

        span {
            i {
                font-size: 7px;
            }

            &::before {
                border: 1px dashed $secondary-color;
            }
        }
    }

    &:active {
        background-color: transparent !important;
        border-color: $secondary-color !important;
        color: $secondary-color !important;

        a {
            color: $secondary-color;
        }

        span {
            i {
                font-size: 7px;
            }

            &::before {
                border: 1px dashed $secondary-color;
            }
        }
    }

    a {
        color: $white;
    }

    span {
        position: absolute;
        right: 20px;
        padding-left: 15px;
        height: 100%;
        display: flex;
        align-items: center;
        top: 0;

        i {
            font-size: 7px;
        }

        &::before {
            content: '';
            width: 0px;
            height: 20px;
            border: 1px dashed $white;
            position: absolute;
            left: 0;
            top: 8px;
        }
    }
}

.FollowsCount {
    color: $primary-color;
    text-align: center;
    h4{
        font-size: 0.95rem;
        font-weight: normal;
        margin-bottom: 0;
        display: inline-block;
    }
}

.ProfileData {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 100%;

    .ProfileImage {
        width: 115px;
        height: 115px;
        margin-right: 13px;

        figure {
            position: static;
            width: 115px;
            height: 115px;
            border-radius: 50%;
            overflow: hidden;
            display: inline-flex;

            img {
                display: block;
                min-width: 115px;
                min-height: 115px;
                object-fit: cover;
            }
        }
    }

    .ProfileContent {
        .ProfileName {
            color: $primary-color;
            font-weight: 500;
            font-size: 1.1rem;
            margin-bottom: 5px;
            display: inline-flex;
            flex-wrap: wrap;
            .Project__Status{
                margin-left: 10px;
                margin-bottom: 0;
            }
            span {
                font-weight: normal;
                font-size: 0.85rem;

                &.HourRate {
                    position: relative;
                    padding-left: 12px;
                    margin: 0 5px;
                    font-weight: 500;
                    font-size: 1.1rem;

                    &::before {
                        content: '';
                        position: absolute;
                        width: 5px;
                        height: 2px;
                        background-color: $primary-color;
                        left: 0;
                        top: 50%;
                        margin-top: -1px;
                    }
                }
            }
        }
        .userRating{
            margin-bottom: 0px;
        }
        .ProfileTitle {
            color: $p-color;
            font-size: 0.95rem;
            font-weight: normal;
            margin-bottom: 5px;
        }

        .LocationTime {
            margin-bottom: 10px;
            address {
                display: inline-block;
                margin-bottom: 0;

                .LocationFlag {
                    margin-right: 5px;

                    img {
                        max-width: 23px;
                    }
                }

                .Location {
                    margin-right: 5px;
                }
            }

            .Time {
                display: inline-block;
                margin-left: 3px;
                padding-left: 10px;
                position: relative;
                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    height: 1px;
                    width: 5px;
                    background-color: $dark-gray;
                }
                div{
                    display: inline-block;
                }
                label{
                    margin-bottom: 0;
                }
            }
            @media screen and (max-width: 550px) {
                display: flex;
                flex-direction: column;
                align-items: center;
                .Time{
                    padding-left: 0;
                    margin-left: 0;
                    margin-top: 5px;
                    text-align: center;
                    &::before{
                        display: none;
                    }
                }
            }
        }

        .LastSeen {
            color: $p-color;
            font-size: 0.85rem;
        }
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;
        .ProfileContent{
            margin-top: 8px;
            margin-bottom: 8px;
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: center;
            .userRating{
                margin-bottom: 5px;
            }
        }
    }
}

[dir='rtl'] .Profile-Card {
    &__title {
        span {
            margin-left: 10px;
            margin-right: 0;
        }
    }
}

[dir='rtl'] .FollowUser {
    a {
        i {
            margin-left: 5px;
            margin-right: 0;
        }
    }
}

[dir='rtl'] .DeleteItem {
    a {
        i {
            margin-left: 5px;
            margin-right: 0;
        }
    }
}

[dir='rtl'] .InviteContest {
    a {
        i {
            margin-left: 5px;
            margin-right: 0;
        }
    }
}
[dir='rtl'] .HireMe {
    span {
        left: 20px;
        right: auto;
        padding-right: 15px;
        padding-left: 0;
        &::before {
            right: 0;
            left: auto;
        }
    }
}
[dir='rtl'] .ProfileData {
    .ProfileImage {
        margin-left: 13px;
        margin-right: 0;
    }
    .ProfileContent {
        .ProfileName {
            span {
                &.HourRate {
                    padding-right: 12px;
                    padding-left: 0;
                    &::before {
                        right: 0;
                        left: auto;
                    }
                }
            }
            .Project__Status{
                margin-right: 10px;
                margin-left: 0;
            }
        }
        .LocationTime {
            address {
                .LocationFlag {
                    margin-left: 5px;
                    margin-right: 0;
                }
                .Location {
                    margin-left: 5px;
                    margin-right: 0;
                }
            }
            .Time {
                margin-right: 3px;
                margin-left: 0;
                padding-right: 10px;
                padding-left: 0;
                &::before {
                    right: 0;
                    left: auto;
                }
            }
            @media screen and (max-width: 550px) {
                .Time{
                    margin-right: 0;
                    margin-left: 0;
                    padding-right: 0;
                    padding-left: 0;
                }
            }
        }
    }
}