html {
    font-size: 14px;
    direction: ltr;
    scroll-behavior: smooth;
    @media (min-width: 768px ) {
        font-size: 16px;
    }
}

body {
    font-family: $font-family;
    color: $dark-gray;
    background-color: $section-color;
    font-size: 0.95rem;
    font-weight: normal;
    text-align: left;
    scroll-behavior: smooth;
    direction: ltr;
    overflow-x: hidden;
}

[dir='rtl'] html {
    direction: rtl;
}

[dir='rtl'] body {
    direction: rtl;
    text-align: right;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.container {
    width: 100%;
    margin: auto;
    @media (min-width: 768px ) {
        max-width: calc(100% - 48px);
    }

}

.nav-menu {
    background-image: url("../imgs/menu.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: inline-block;
    height: 30px;
    width: 30px;
    @media (min-width: 1024px) {
        display: none;
    }
}

.dividerBTW {
    content: "";
    margin-left: 10px;
    margin-right: 10px;
    border-right: 1px solid $white;
    height: 30px;
    cursor: auto;
}

figure {
    margin-bottom: 0;
}

.lg {
    font-size: 2.5rem;
}

.bg {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.main-bg {
    &.visible{
        background-image: url('../imgs/main-bg.webp');
    }
}

.inner-bg{
    background-color: $primary-dark;
    //margin-bottom: 50px;
}

a {
    text-decoration: none;
    color: $primary-color;
    &:hover,
    &:focus,
    &:active {
        color: $secondary-color;
        text-decoration: none;
        outline: transparent auto 0px;
    }
}

select:focus,
input:focus,
button:focus {
    outline: none;
}

.box-shadow {
    box-shadow: $box-shadow;
    -webkit-box-shadow: $box-shadow;
}

.headers {
    h5 {
        font-size: 2.5rem;
        font-weight: 500;
    }

    h4 {
        font-size: 1.87500rem;
        font-weight: 500;
        color: $white;
    }
}

.headers--underline {
    &::after {
        display: flex;
        content: '';
        border-bottom: 3px solid $primary-color;
        width: 6.25rem;
        height: .5rem;
    }
}

.sectionHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: $header-bottom-space;
}

.sectionTopBottomSpaces {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
}

.img-container {
    overflow: hidden;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    position: absolute;
    bottom: calc(100% - 200px);
    margin-bottom: -75px;
    left: 50%;
    margin-left: -75px;
}

.slick-dots li button:before,
.slick-dots li.slick-active button:before {
    color: $pink;
    opacity: unset;
    font-size: 0.75rem;
}

.slick-dots li.slick-active button:before {
    font-size: 1.25rem;
}

.goToTarget {
    width: 40px;
    height: 40px;
    background-color: $secondary-color;
    border-radius: 50%;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -20px;
    font-size: 0.8rem;
    color: $white;
    transition: all 0.3s;
    cursor: pointer;
    &:hover{
        background-color: $primary-color;
    }
}


.list_item {
    flex-direction: column;
    border-radius: $border-radius;
    background-color: $white;
    height: 350px;
    position: relative;
    border-bottom: 5px solid $secondary-color;
    &:focus{
        outline: transparent auto 0px;
    }
}

.circlePhoto {
    width: 150px;
}

li {
    a {
        transition: all 0.3s;
        &:hover {
            color: $secondary-color !important;
        }
    }
}

.chooseLangBox {
    border-radius: 50px;
    width: 100px;
    appearance: listbox;
    border: 2px solid $white;
    background-color: transparent;
    color: $white;
    padding-left: 10px !important;
    padding-right: 25px !important;
    font-weight: 500;
    &:focus {
        outline: none;
    }
}

[dir='rtl'] .chooseLangBox {
    padding-right: 10px !important;
    padding-left: 25px !important;
}

.dropdown-menu.show {
    width: 100%;
}

.accordion{
    .card{
        margin-bottom: 30px;
        border: 0 none;
        border-radius: 10px !important;
        .card-header{
            padding: 0;
            background-color: $primary-color;
            border-bottom: 0;
            border-radius: 10px 10px 0 0 !important;
            button{
                padding: 1rem;
                padding-right: 2.8rem;
                width: 100%;
                text-align: left;
                color: $white;
                text-decoration: none;
                position: relative;
                display: flex;
                align-items: center;
                background-color: transparent !important;
                border: 0 none;
                margin: 0;
                span{
                    &.icon{
                        font-size: 22px;
                        margin-right: 10px;
                    }
                }
                &::before{
                    content: '\e91f';
                    position: absolute;
                    color: $white;
                    right: 1rem;
                    top: 50%;
                    margin-top: -7px;
                    font-size: 14px;
                    font-family: 'icomoon' !important;
                    speak: none;
                    font-style: normal;
                    font-weight: normal;
                    -webkit-font-feature-settings: normal;
                    font-feature-settings: normal;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }
                &::after{
                    display: none;
                }
                &:focus{
                    background-color: transparent !important;
                    color: $white;
                }
                &:hover{
                    color: $white;
                }
            }
        }
        .card-body{
            p{
                margin-bottom: 0;
            }
        }
        &.active-panel{
            .card-header{
                button{
                    &::before{
                        content: '\e936';
                    }
                }
            }
        }
    }
}
[dir='rtl'] .accordion{
    .card{
        .card-header{
            button{
                text-align: right;
                padding-left: 2.8rem;
                padding-right: 1rem;
                span{
                    &.icon{
                        font-size: 22px;
                        margin-left: 10px;
                    }
                }
                &::before{
                    left: 1rem;
                    right: auto;
                }
            }
        }
    }
}
.card{
    margin-bottom: 30px;
    border: 0 none;
    border-radius: 0 0 10px 10px !important;
    .card-header{
        background-color: $primary-color;
        border-bottom: 0;
        border-radius: 10px 10px 0 0 !important;
        color: $white;
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        h1{
            font-size: 1rem;
            display: inline-block;
            margin-bottom: 0;
        }
        h2{
            font-size: 1rem;
            display: inline-block;
            margin-bottom: 0;
        }
        span{
            font-size: 1rem;
            margin-right: 10px;
        }
        .btn{
            color: $white;
            text-transform: capitalize;
            transition: all 0.3s;
            border: 0 none;
            display: inline-flex;
            align-items: center;
            span{
                font-size: 0.85rem;
                margin-right: 5px;
                &.icon-delete{
                    font-size: 0.65rem;
                }
            }
            &:hover{
                color: $secondary-color;
            }
            &:focus{
                box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
            }
        }
        @media screen and (max-width: 768px) {
            flex-wrap: wrap;
            justify-content: center;
            div{
                &:first-child{
                    text-align: center;
                }
            }
        }
    }
    .card-body{
        p{
            margin-bottom: 0;
        }
    }
}
.InnerPageContainer{
    padding-bottom: 50px;
}
.nav-tabs{
    background-color: $white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 2px solid $pink;
    a{
        margin-bottom: 0 !important;
        border: 0 none !important;
        color: $dark-gray;
        border-top-left-radius: 10px !important;
        border-top-right-radius: 10px !important;
        &.active{
            background-color: $pink !important;
            color: $white !important;
        }
    }
}
.loggedin{
    padding-left: 70px;
    width: 100%;
    min-height: 725px;
    position: relative;
}
[dir='rtl'] .loggedin{
    padding-right: 70px;
    padding-left: 0;
}
.LoggedNav{
    width: 70px;
    height: 100%;
    background-color: $white;
    position: absolute;
    left: 0px;
    nav{
        padding: 25px 0 0 0;
        margin: 0;
        li{
            list-style-type: none;
            text-align: center;
            padding-bottom: 15px;
            a{
                background-color: $primary-color;
                color: $white;
                padding: 10px;
                border-radius: 5px;
                text-decoration: none;
                font-size: 1.8rem;
                display: inline-flex;
                line-height: 1;
                justify-content: center;
                align-items: center;
                max-width: 48.8px;
                &:hover{
                    background-color: $secondary-color;
                    color: $white !important;
                }
                &.active{
                    background-color: $secondary-color;
                    color: $white !important;
                }
                span{
                    &.icon-envelope{
                        font-size: 1.5rem;
                    }
                }
            }
        }
    }
}
.section__title{
    background-color: $white;
    border-radius: $border-radius-smaller;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 0;
    box-shadow: $box-shadow;
    -webkit-box-shadow: $box-shadow;
    flex-wrap: wrap;
    &__text{
        color: $primary-color;
        font-size: 1.2rem;
        font-weight: 500;
        text-transform: capitalize;
        h1{
            font-size: 1.2rem;
            font-weight: 500;
            display: inline-block;
            margin-bottom: 0;
        }
        .icon{
            margin-right: 8px;
        }
    }
    a{
        color: $primary-color;
        text-transform: uppercase;
        font-size: 0.9rem;
        transition: all 0.3s;
        cursor: pointer;
        .icon{
            margin-right: 8px;
        }
        &:hover{
            color: $secondary-color;
        }
    }
    .btn{
        color: $primary-color;
        text-transform: uppercase;
        font-size: 0.9rem;
        transition: all 0.3s;
        cursor: pointer;
        padding: 0;
        border: 0 none;
        margin-left: 16px;
        .icon{
            margin-right: 8px;
        }
        .fa{
            margin-right: 8px;
        }
        .fas{
            margin-right: 8px;
        }
        .far{
            margin-right: 8px;
        }
        &:hover{
            color: $secondary-color;
        }
        &:focus{
            box-shadow: 0 0 0 0.2rem rgba(0,123,255,0);
        }
        &:first-child{
            margin-left: 0;
        }
        h2{
            font-size: 0.9rem;
            font-weight: normal;
            display: inline-block;
            margin-bottom: 0;
        }
        h3{
            font-size: 0.9rem;
            font-weight: normal;
            display: inline-block;
            margin-bottom: 0;
        }
    }
    @media screen and (max-width: 550px) {
        flex-direction: column;
        div{
            &:last-child(){
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                .btn{
                    margin: 8px 5px 0;
                }
            }
        }
    }
}
.CustomPopup{
    .modal-content{
        border: 0 none;
        .modal-header{
            background-color: $primary-dark;
            border-bottom: 0 none;
            align-items: center;
            .close{
                color: $white;
                opacity: 1;
            }
            .modal-title{
                color: $white;
                font-size: 1.2rem;
                font-weight: 500;
            }
        }
        .modal-footer{
            justify-content: center;
        }
        table{
            p{
                margin-bottom: 0;
            }
        }
        .fa-star{
            color: $secondary-color;
        }
    }
}
:focus{
    outline: -webkit-focus-ring-color auto 0px;
}
.react-datepicker-wrapper{
    width: 100%;
}
.SingleDatePickerInput__withBorder{
    border-radius: .25rem;
}
.DateInput{
    border-radius: .25rem;
}
.DateInput_input{
    border-radius: .25rem;
}
.success-message{
    position: fixed;
    background-color: #89c33e;
    color: $white;
    border-radius: 50px;
    display: inline-flex;
    font-size: 0.85rem;
    padding: 0.5rem 1rem;
    bottom: 60px;
    transition: all linear 0.3s;
    z-index: 9999;
    align-items: center;
    p{
      margin-right: 10px;
      margin-bottom: 0;
    }
    #CloseSuccess{
      color: $white;
      width: 15px;
      height: 15px;
      border: 1px solid $white;
      text-align: center;
      line-height: 13px;
      font-size: 8px;
      border-radius: 20px;
      margin-right: 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
    }
}
.danger-message{
    position: fixed;
    background-color: #f8d7da;
    color: #721c24;
    border-radius: 50px;
    display: inline-flex;
    font-size: 0.85rem;
    padding: 0.5rem 1rem;
    bottom: 60px;
    transition: all linear 0.3s;
    z-index: 9999;
    align-items: center;
    p{
      margin-right: 10px;
      margin-bottom: 0;
    }
    #CloseSuccess{
      color: #721c24;
      width: 15px;
      height: 15px;
      border: 1px solid #721c24;
      text-align: center;
      line-height: 13px;
      font-size: 8px;
      border-radius: 20px;
      margin-right: 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
    }
}
.warning-message{
    position: fixed;
    background-color: #fff3cd;
    color: #856404;
    border-radius: 50px;
    display: inline-flex;
    font-size: 0.85rem;
    padding: 0.5rem 1rem;
    top: 20px;
    left: 20px;
    transition: all linear 0.3s;
    z-index: 9999;
    align-items: center;
    p{
      margin-right: 10px;
      margin-bottom: 0;
    }
    #CloseSuccess{
      color: #856404;
      width: 15px;
      height: 15px;
      border: 1px solid #856404;
      text-align: center;
      line-height: 13px;
      font-size: 8px;
      border-radius: 20px;
      margin-right: 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
    }
}

.LoadingContainer{
    width: 100%;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    .LancersLoading {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
        div {
            position: absolute;
            border: 4px solid $secondary-color;
            opacity: 1;
            border-radius: 50%;
            animation: LancersLoading 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
            &:nth-child(2) {
                animation-delay: -0.5s;
            }
        }
        @keyframes LancersLoading {
            0% {
                top: 36px;
                left: 36px;
                width: 0;
                height: 0;
                opacity: 1;
            }
            100% {
                top: 0px;
                left: 0px;
                width: 72px;
                height: 72px;
                opacity: 0;
            }
        }
    }
}

.CommentsList{
    li{
        padding-bottom: 10px;
        border-bottom: 1px solid $section-color-darker;
        margin-bottom: 10px;
        padding-right: 15px;
        position: relative;
        .Comment{
            &__userInfo{
                display: flex;
                margin-bottom: 10px;
                .UserPic{
                    width: 40px;
                    height: 40px;
                    border-radius: 40px;
                    overflow: hidden;
                    margin-right: 10px;
                    img{
                        width: 40px;
                        min-height: 40px;
                        object-fit: cover;
                    }
                }
                .CommentContent{
                    &__Details{
                        width: calc(100% - 50px);
                        display: flex;
                        flex-wrap: wrap;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        h3{
                            font-size: 1rem;
                            font-weight: 900;
                            margin-bottom: 0;
                        }
                        &__PostTime{
                            color: $p-color;
                            font-size: 0.75rem;
                        }
                    }
                }
                @media screen and (max-width: 410px) {
                    padding-top: 30px;
                }
            }
        }
        .CommentContent{
            display: flex;
            justify-content: center;
            height: 100%;
            flex-direction: column;
            form{
                .btn-secondary{
                    margin-left: 10px;
                }
            }
            .ProfileSkills{
                li{
                    border-bottom: 0 none;
                    position: inherit;
                }
            }
        }
        .dropdown{
            position: absolute;
            top: 0;
            right: 0;
            button{
                &.btn{
                    background-color: transparent;
                    border-color: transparent;
                    padding: 0;
                    color: $primary-dark;
                    border: 0;
                    width: 15px;
                    span{
                        &.icon{
                            font-size: 1.5rem;
                        }
                    }
                }
            }
            .dropdown-menu{
                background-color: $primary-dark;
                border-color: $primary-dark;
                padding: 0;
                border: 0 none;
                overflow: hidden;
                transform: translate3d(0px, 0px, 0px) !important;
                right: 0 !important;
                left: auto !important;
                top: 30px !important;
                min-width: 8rem;
                a{
                    color: $white;
                    transition: all 0.3s;
                    padding: .5rem;
                    display: flex;
                    width: 100%;
                    font-size: 0.85rem;
                    align-items: center;
                    &:hover{
                        background-color: $secondary-color;
                        color: $white !important;
                    }
                    &:focus{
                        background-color: $secondary-color;
                        color: $white !important;
                    }
                    span{
                        &.icon{
                            font-size: 0.65rem;
                            margin-right: 10px;
                        }
                    }
                }
            }
            &.show{
                .dropdown-menu{
                    text-align: left;
                }
            }
        }
    }
}
.ListDragabol{
    width: 100%;
    margin-bottom: 20px;
    li{
        &.ListItemDragabol{
            border: 1px solid $section-color-darker;
            border-radius: 5px;
            background-color: $white;
            margin: 10px 0;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding: 10px;
            justify-content: space-between;
            opacity: 1 !important;
            visibility: visible !important;
            z-index: 99999;
            img{
                margin-right: 10px;
                width: 150px;
                max-width: 150px;
                display: inline-block;
            }
        }
    }
}
.ListItemDragabol{
    border: 1px solid $p-color;
    border-radius: 5px;
    background-color: $white;
    margin: 10px 0;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 10px;
    justify-content: space-between;
    z-index: 99999;
    img{
        margin-right: 10px;
        width: 150px;
        max-width: 150px;
        display: inline-block;
    }
    .File{
        &__Thumb{
            display: flex;
            min-width: 0;
            overflow: hidden;
            width: 100px;
            height: 100px;
            padding: 4px;
            box-sizing: border-box;
            border-radius: 4px;
            border: 1px solid #eaeaea;
            margin-right: 8px;
            .Image__Container{
                display: inline-flex;
                overflow: hidden;
                justify-content: center;
                align-items: center;
                width: 100%;
                img{
                    display: block;
                    width: auto;
                    height: 100%;
                }
                i{
                    font-size: 48px;
                    color: $primary-color;
                }
            }
        }
        &__Content{
            margin-left: 5px;
            width: calc(100% - 105px);
            h2{
                color: $primary-color;
                font-weight: 900;
                font-size: 0.85rem;
                margin-bottom: 0;
            }
            .progress{
                margin-bottom: 4px;
            }
        }
        &__Uploaded{
            color: $green;
            font-size: 0.75rem;
        }
    }
}
.ListDragabolTable{
    .ListItemDragabolRow{
        display: table-row;
        border: 0 none;
        padding: 0;
    }
}
.ListItemDragabolRow{
    background-color: #fff;
    z-index: 99999;
    display: table;
    border: 1px solid $p-color;
    td{
        padding: .75rem;
        vertical-align: top;
        &:nth-child(1){
            width: 10%;
        }
        &:nth-child(2){
            width: 70%;
        }
        &:nth-child(3){
            width: 20%;
        }
    }
}
.deleteItem{
    color: $red;
    display: inline-flex;
    align-items: center;
    border: 0 none;
    background-color: transparent;
    span{
        margin-right: 5px;
        &.icon{
            font-size: 0.55rem;
        }
    }
}
.SignTo{
    display: flex;
    flex-direction: column;
    align-items: center;
    h4{
        font-weight: 900;
        color: $primary-color;
        width: 100%;
        text-align: center;
        font-size: 1.2rem;
        text-transform: uppercase;
    }
    p{
        width: 100%;
        text-align: center;
        margin-bottom: 15px !important;
    }
    &__Actions{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        button{
            margin-right: 15px;
            background-color: transparent;
            border-color: $primary-color;
            color: $primary-color;
            &:last-child{
                margin-right: 0;
            }
        }
        @media screen and (max-width: 400px) {
            button{
                margin-right: 5px;
                margin-bottom: 10px;
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
    .icon-lock{
        font-size: 3.5rem;
        color: $secondary-color;
        margin-bottom: 20px;
    }
}
.btn-third{
    display: inline-flex;
    align-items: center;
    background-color: $purple;
    border-color: $purple;
    color: $white;
    border-radius: 50px;
    transition: all 0.3s;
    padding: .375rem 1.75rem;
    justify-content: center;
    span{
        margin-right: 5px;
    }
    &:hover{
        background-color: transparent;
        color: $purple;
    }
}

form{
    small{
        width: 100%;
        display: block;
        margin-bottom: .5rem;
    }
}
.error{
    &__message{
        width: 100%;
        display: block;
        color: $red;
        font-size: 0.85rem;
        font-weight: 900;
    }
}
.filepond--root{
    margin-bottom: 0;
}
.filepond--action-revert-item-processing{
    display: none;
}
.filepond--file-status{
    justify-content: center;
    min-height: 26px;
    .filepond--file-status-sub{
        display: none;
    }
}
.head-title{
    width: 100%;
    display: flex;
    align-items: center;
    background-color: $primary-dark;
    color: $white;
    font-size: 1.2rem;
    padding: 10px;
    border-radius: 5px;
    margin: 10px 0;
    justify-content: space-between;
    text-transform: capitalize;
    .btn{
        color: $white;
        text-transform: uppercase;
        font-size: 0.9rem;
        transition: all 0.3s;
        cursor: pointer;
        padding: 0;
        border: 0 none;
        .icon{
            margin-right: 8px;
        }
        &:hover{
            color: $secondary-color;
        }
        &:focus{
            box-shadow: 0 0 0 0.2rem rgba(0,123,255,0);
        }
    }
}
[dir='rtl'] .head-title{
    .btn{
        .icon{
            margin-left: 8px;
            margin-right: 0px;
        }
    }
}
table{
    thead{
        tr{
            th{
                background-color: $primary-dark;
                color: $white;
            }
        }
    }
}
.tooltip{
    font-family: $font-family;
    font-size: 0.95rem;
    font-weight: normal;
    text-transform: capitalize;
}
.modal-header{
    text-transform: capitalize;
    .close{
        margin: -1rem;
        margin-left: auto;
    }
}
.custom-switch{
    padding-left: 3.5rem;
    padding-right: 0;
    .custom-control-label{
        &::before{
            left: -3.5rem;
            right: 0;
            width: 3rem;
            height: 1.5rem;
            border-radius: 3rem;
        }
        &::after{
            left: calc(-3.5rem + 2px);
            right: 0;
            transition: all 0.3s;
            width: calc(1.5rem - 4px);
            height: calc(1.5rem - 4px);
            border-radius: 1.5rem;
        }
    }
    .custom-control-input:focus~.custom-control-label::before{
        box-shadow: 0 0 0 0.2rem rgba(255, 183, 39, 0.25);
    }
    .custom-control-input:focus:not(:checked)~.custom-control-label::before{
        border-color: $secondary-color;
    }
    .custom-control-input:checked~.custom-control-label{
        &::before{
            background-color: $secondary-color;
            border-color: $secondary-color;
        }
        &::after{
            left: calc(-3.5rem + 26px);
            transform: translateX(0);
        }
    }
}
.custom-control-input{
    left: 0;
    right: auto;
}
label{
    text-transform: capitalize;
}
form label{
    font-weight: 500;
    color: $primary-color;
    font-size: 1.1rem;
}
.filepond--root{
    font-family: $font-family;
}
.filepond--file-action-button.filepond--file-action-button svg{
    top: 0rem;
    position: absolute;
    left: 0;
}
.ql-editor{
    text-align: left;
    min-height: 220px;
}
[dir='rtl'] .ql-editor{
    text-align: right;
}
.invalid{
    .css-bg1rzq-control{
        border-color: #dc3545;
        &:hover{
            border-color: #dc3545;
        }
        &:focus{
            border-color: #dc3545;
        }
    }
    .ql-toolbar.ql-snow{
        border: 1px solid #dc3545;
    }
    .ql-container.ql-snow{
        border: 1px solid #dc3545;
    }
}
.input-curved{
    border-radius: 50px;
}
.fullPageLoading{
    width: 100%;
    height: 100%;
    background-color: rgba($section-color,0.8);
    position: absolute;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}


@keyframes Lancersin__Loading__ldio-1 {
  0% { top: 31.918999999999993px; height: 54.162000000000006px }
  50% { top: 43.07px; height: 31.86px }
  100% { top: 43.07px; height: 31.86px }
}
@keyframes Lancersin__Loading__ldio-2 {
  0% { top: 34.70674999999999px; height: 48.5865px }
  50% { top: 43.07px; height: 31.86px }
  100% { top: 43.07px; height: 31.86px }
}
@keyframes Lancersin__Loading__ldio-3 {
  0% { top: 37.494499999999995px; height: 43.011px }
  50% { top: 43.07px; height: 31.86px }
  100% { top: 43.07px; height: 31.86px }
}
.Lancersin__Loading__ldio div { position: absolute; width: 14.16px }.Lancersin__Loading__ldio div:nth-child(1) {
  left: 22.419999999999998px;
  background: #1d224e;
  animation: Lancersin__Loading__ldio-1 0.8849557522123893s cubic-bezier(0,0.5,0.5,1) infinite;
  animation-delay: -0.17699115044247787s
}
.Lancersin__Loading__ldio div:nth-child(2) {
  left: 51.919999999999995px;
  background: #ffb727;
  animation: Lancersin__Loading__ldio-2 0.8849557522123893s cubic-bezier(0,0.5,0.5,1) infinite;
  animation-delay: -0.08849557522123894s
}
.Lancersin__Loading__ldio div:nth-child(3) {
  left: 81.42px;
  background: #623b6a;
  animation: Lancersin__Loading__ldio-3 0.8849557522123893s cubic-bezier(0,0.5,0.5,1) infinite;
  animation-delay: undefineds
}

.Lancersin__Loading__Pulse {
  width: 118px;
  height: 118px;
  display: inline-block;
  overflow: hidden;
}
.Lancersin__Loading__ldio {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.Lancersin__Loading__ldio div { box-sizing: content-box; }
.inner{
    &__title{
        background-color: $primary-color;
        color: $white;
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.9rem;
        h2{
            color: $white;
            font-size: 1rem;
            margin: 0;
            padding: 0;
        }
    }
    &__subtitle{
        background-color: $section-color-darker;
        color: $primary-color;
        font-size: 0.9rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.9rem;
        font-weight: 500;
        h2{
            color: $primary-color;
            font-size: 0.9rem;
            margin: 0;
            padding: 0;
            font-weight: 500;
        }
    }
}
.Accordion{
    &__Active{
        &__Head{
            .btn{
                position: relative;
                &::before{
                    content: '\e936';
                    position: absolute;
                    right: 1rem;
                    top: 50%;
                    margin-top: -8px;
                    color: $white;
                    font-family: 'icomoon' !important;
                    speak: none;
                    font-style: normal;
                    font-weight: normal;
                    -webkit-font-feature-settings: normal;
                    font-feature-settings: normal;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }
                &:hover{
                    &::before{
                        color: $secondary-color;
                    }
                }
            }
        }
    }
    &__Head{
        .btn{
            position: relative;
            &::before{
                content: '\e91f';
                position: absolute;
                right: 1rem;
                top: 50%;
                margin-top: -8px;
                color: $white;
                font-family: 'icomoon' !important;
                speak: none;
                font-style: normal;
                font-weight: normal;
                -webkit-font-feature-settings: normal;
                font-feature-settings: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
            &:hover{
                &::before{
                    color: $secondary-color;
                }
            }
        }
    }
}
.ql-container{
    min-height: 120px;
    font-family: $font-family;
    font-size: 0.95rem;
}
.file{
    &__size{
        color: $p-color;
        display: inline-block;
    }
    &__type{
        background-color: $purple-dark;
        color: $white;
        border-radius: 50px;
        padding: 2px 20px;
        display: inline-block;
        font-size: 0.85rem;
        margin-right: 10px; 
    }
}
.CommentsList{
    .filepond--item{
        position: absolute !important;
        display: block;
        padding-left: 0;
        margin: .25em;
        padding: 0;
        border-bottom: 0 none;
    }
}
.attachments{
    &__title{
        margin-bottom: 0;
        span{
            background-color: $purple-dark;
            color: $white;
            font-size: 0.95rem;
            padding: 0.25rem 1rem;
            margin: 10px 0;
            display: inline-block;
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
        }
    }
}
[dir='rtl'] .attachments{
    &__title{
        span{
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
        }
    }
}
.Comments{
    &__Container{
        .card-body{
            position: relative;
            .fullPageLoading{
                top: 0;
                left: 0;
                z-index: 5;
            }
        }
    }
    &__actions{
        position: absolute;
        top: 0;
        right: 0;
    }
}
.dropUploader{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    &.active{
        border-color: #2196f3;
    }
    &.accept{
        border-color: #00e676;
    }
    &.reject{
        border-color: #ff1744;
    }
}
.btnWloading{
    display: inline-flex;
    align-items: center;
    &__text{
        margin-left: 5px;
    }
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.modal{
    z-index: 99999;
}
.SearchCard{
    .accordion{
        overflow: visible;
        .card{
            border-radius: 10px !important;
            border-left: 5px solid $secondary-color;
            overflow: visible;
        }
    }
}
[dir='rtl'] .SearchCard{
    .accordion{
        .card{
            border-right: 5px solid $secondary-color;
            border-left: 0 none;
        }
    }
}
.form-control.is-invalid, .was-validated .form-control:invalid{
    padding-right: calc(1.5em + .75rem);
    padding-left: .75rem;
    background-position: $right calc(.375em + .1875rem) center;
}
[dir='rtl'] .form-control.is-invalid, [dir='rtl'] .was-validated .form-control:invalid{
    padding-left: calc(1.5em + .75rem);
    padding-right: .75rem;
}
.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid{
    padding-right: calc(1.5em + .75rem);
    padding-left: .75rem;
    background-position: top calc(.375em + .1875rem) $right calc(.375em + .1875rem);
}
[dir='rtl'] .was-validated textarea.form-control:invalid, [dir='rtl'] textarea.form-control.is-invalid{
    padding-left: calc(1.5em + .75rem);
    padding-right: .75rem;
}
.color{
    &__picker{
        display: flex;
        align-items: flex-start;
        flex-direction: column !important;
        button{
            margin-bottom: 0px;
            padding: 0;
            width: 50px;
            height: 50px;
        }
    }
    &__List{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    &__item{
        border-radius: 50px;
        width: 50px;
        height: 50px;
        position: relative;
        display: inline-block;
        margin-right: 5px;
        border: 1px solid $section-color-darker;
        span{
            &.fa{
                position: absolute;
                top: 50%;
                margin-top: -10px;
                right: 50%;
                margin-right: -10px;
                background-color: $white;
                border-radius: 50px;
                width: 20px;
                height: 20px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                &:hover{
                    cursor: pointer;
                    color: $red;
                }
            }
        }
        &:last-child{
            margin-right: 0;
        }
    }
}
.btn-danger{
    border-radius: 25px;
    padding: .375rem 1.75rem;
}
a:focus, a:active, a:hover {
    outline: none;
    -moz-outline-style: none;
}
// .basic-multi-select{
//     z-index: 99999;
// }
.custom-control{
    z-index: 0;
}
.NoPermissions{
    &__Card{
        background-color: $white;
        border-radius: $border-radius-smaller;
        box-shadow: $box-shadow;
        max-width: 400px;
        margin: 50px 0;
        padding: 24px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column;
        i{
            font-size: 70px;
            color: $secondary-color;
            margin-bottom: 16px;
        }
        h2{
            font-size: 1.2rem;
            font-weight: normal;
            color: $primary-color;
            text-align: center;
            margin-bottom: 16px;
            line-height: 1.5;
        }
        a{
            i{
                font-size: 14px;
                color: $white;
                margin-bottom: 0;
            }
        }
    }
}
.No__Shadow{
    box-shadow: 0px 0px 0px 0px rgba(29, 34, 78, 0%) !important;
}
.slick-prev.slick-disabled, .slick-next.slick-disabled{
    cursor: default;
}

[dir="rtl"] .slick-prev.slick-disabled, [dir="rtl"] .slick-next.slick-disabled{
    cursor: default;
}

[dir="rtl"] .slick-prev.slick-disabled::before, [dir="rtl"] .slick-next.slick-disabled::before{
    opacity: .25;
}

.slick-prev.slick-disabled:hover, .slick-next.slick-disabled:hover{
    background: $purple;
}

[dir="rtl"] .slick-prev.slick-disabled:hover, [dir="rtl"] .slick-next.slick-disabled:hover{
    background: $purple;
}
.webPages{
    line-height: 1.8;
    ul{
        li{
            padding-left: 20px;
            position: relative;
            margin-bottom: 0.5rem;
            &::before{
                content: '';
                width: 10px;
                height: 5px;
                border-radius: 10px;
                background-color: $secondary-color;
                position: absolute;
                top: 9px;
                left: 0;
            }
        }
    }
    h3{
        font-weight: 900;
        font-size: 1rem;
        margin: 1rem 0;
        line-height: 1.8;
    }
    p{
        margin-bottom: 0.5rem !important;
        line-height: 1.8;
    }
    a{
        text-decoration: underline;
    }
    ol{
        li{
            font-weight: 900;
            ul{
                margin-top: 0.5rem;
                li{
                    font-weight: normal;
                }
            }
        }
    }
}
[dir='rtl'] .img-container {
    right: 50%;
    left: auto;
    margin-right: -75px;
    margin-left: 0;
}
[dir='rtl'] .accordion{
    .card{
        .card-header{
            button{
                span{
                    &.icon{
                        margin-left: 10px;
                        margin-right: 0;
                    }
                }
                &[aria-expanded="true"]{
                    left: 1rem;
                    right: auto;
                }
                &[aria-expanded="false"]{
                    left: 1rem;
                    right: auto;
                }
            }
        }
    }
    .card:has(.show){
        .card-header{
            button{
                &::after{
                    left: 0;
                    right: auto;
                }
            }
        }
    }
}
[dir='rtl'] .card{
    .card-header{
        span{
            margin-left: 10px;
            margin-right: 0;
        }
        .btn{
            span{
                margin-left: 5px;
                margin-right: 0;
            }
        }
    }
}
[dir='rtl'] .LoggedNav{
    right: 0px;
    left: auto;
}
[dir='rtl'] .section__title{
    &__text{
        .icon{
            margin-left: 8px;
            margin-right: 0;
        }
    }
    a{
        .icon{
            margin-left: 8px;
            margin-right: 0;
        }
    }
    .btn{
        margin-right: 16px;
        margin-left: 0;
        .icon{
            margin-left: 8px;
            margin-right: 0;
        }
        .fa{
            margin-left: 8px;
            margin-right: 0;
        }
        .fas{
            margin-left: 8px;
            margin-right: 0;
        }
        .far{
            margin-left: 8px;
            margin-right: 0;
        }
        &:first-child{
            margin-right: 0;
            margin-left: 0;
        }
    }
}
[dir='rtl'] .success-message{
    p{
      margin-left: 10px;
      margin-right: 0;
    }
    #CloseSuccess{
      margin-left: 0;
      margin-right: 0;
    }
}
[dir='rtl'] .danger-message{
    p{
      margin-left: 10px;
      margin-right: 0;
    }
    #CloseSuccess{
      margin-left: 0;
      margin-right: 0;
    }
}
[dir='rtl'] .warning-message{
    right: 20px;
    left: auto;
    p{
      margin-left: 10px;
      margin-right: 0;
    }
    #CloseSuccess{
      margin-left: 0;
      margin-right: 0;
    }
}
[dir='rtl'] .CommentsList{
    li{
        padding-left: 15px;
        padding-right: 0;
        .Comment{
            &__userInfo{
                .UserPic{
                    margin-left: 10px;
                    margin-right: 0;
                }
            }
        }
        .CommentContent{
            form{
                .btn-secondary{
                    margin-right: 10px;
                    margin-left: 0;
                }
            }
        }
        .dropdown{
            left: 0;
            right: auto;
            .dropdown-menu{
                left: 0 !important;
                right: auto !important;
                a{
                    span{
                        &.icon{
                            margin-left: 10px;
                            margin-right: 0;
                        }
                    }
                }
            }
            &.show{
                .dropdown-menu{
                    text-align: right;
                }
            }
        }
    }
}
[dir='rtl'] .ListDragabol{
    li{
        &.ListItemDragabol{
            img{
                margin-left: 10px;
                margin-right: 0;
            }
        }
    }
}
[dir='rtl'] .ListItemDragabol{
    img{
        margin-left: 10px;
        margin-right: 0;
    }
    .File{
        &__Thumb{
            margin-left: 8px;
            margin-right: 0;
        }
        &__Content{
            margin-right: 5px;
            margin-left: 0;
        }
    }
}
[dir='rtl'] .deleteItem{
    span{
        margin-left: 5px;
        margin-right: 0;
    }
}
[dir='rtl'] .SignTo{
    &__Actions{
        button{
            margin-left: 15px;
            margin-right: 0;
            &:last-child{
                margin-left: 0;
                margin-right: 0;
            }
        }
        @media screen and (max-width: 400px) {
            button{
                margin-left: 5px;
                margin-right: 0;
                margin-bottom: 10px;
                &:last-child{
                    margin-left: 0;
                }
            }
        }
    }
}
[dir='rtl'] .btn-third{
    span{
        margin-left: 5px;
        margin-right: 0;
    }
}
[dir='rtl'] .modal-header{
    .close{
        margin-right: auto;
        margin-left: 0;
    }
}
[dir='rtl'] .custom-switch{
    padding-right: 3.5rem;
    padding-left: 0;
    .custom-control-label{
        &::before{
            right: -3.5rem;
            left: 0;
        }
        &::after{
            right: calc(-3.5rem + 2px);
            left: 0;
        }
    }
    .custom-control-input:checked~.custom-control-label{
        &::after{
            right: calc(-3.5rem + 26px);
            left: auto;
        }
    }
}
[dir='rtl'] .custom-control-input{
    right: 0;
    left: auto;
}
[dir='rtl'] .Accordion{
    &__Active{
        &__Head{
            .btn{
                &::before{
                    left: 1rem;
                    right: auto;
                }
            }
        }
    }
    &__Head{
        .btn{
            &::before{
                left: 1rem;
                right: auto;
            }
        }
    }
}
[dir='rtl'] .file{
    &__type{
        margin-left: 10px; 
        margin-right: 0;
    }
}
[dir='rtl'] .Comments{
    &__Container{
        .card-body{
            .fullPageLoading{
                right: 0;
                left: auto;
            }
        }
    }
    &__actions{
        left: 0;
        right: auto;
    }
}
[dir='rtl'] .btnWloading{
    &__text{
        margin-right: 5px;
        margin-left: 0;
    }
}
[dir='rtl'] .color{
    &__item{
        margin-left: 5px;
        margin-right: 0;
        span{
            &.fa{
                margin-left: -10px;
                margin-right: 0;
                left: 50%;
                right: auto;
            }
        }
        &:last-child{
            margin-left: 0;
            margin-right: 0;
        }
    }
}
[dir='rtl'] .filepond--file-action-button.filepond--file-action-button svg{
    right: 0;
    left: auto;
}
[dir='rtl'] .webPages{
    ul{
        li{
            padding-right: 20px;
            padding-left: 0;
            &::before{
                right: 0;
                left: auto;
            }
        }
    }
}