.header {
    &__topSide {
        background-color: $primary-color;
        &--content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-height: 60px;
            flex-wrap: wrap;
            &--leftSide {
                min-height: 60px;
                ul {
                    font-weight: 500;
                    min-height: 60px;
                    display: inline-flex;
                    align-items: center;
                    li {
                        min-height: 30px;
                        float: left;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        color: $white;
                        transition: all 0.3s;
                        text-transform: uppercase;
                        &:hover{
                            color: $secondary-color;
                        }
                        i {
                            font-size: 20px;
                            margin-right: 10px;
                        }
                    }
                    .btnIcon{
                        background-color: transparent;
                        border-color: transparent;
                        &:before{
                            display: none !important;
                        }
                        &:hover{
                            color: $secondary-color;
                            background-color: transparent;
                            border-color: transparent;
                            small{
                                color: $white;
                            }
                        }
                        &:focus{
                            color: $secondary-color;
                            background-color: transparent;
                            border-color: transparent;
                            small{
                                color: $white;
                            }
                        }
                        &:active{
                            color: $secondary-color !important;
                            background-color: transparent !important;
                            border-color: transparent !important;
                            small{
                                color: $white !important;
                            }
                        }
                    }
                    .userDropdown__button{
                        border-radius: 0;
                        display: inline-flex;
                        align-items: center;
                        height: 100%;
                        padding-right: 20px;
                        position: relative;
                        min-height: 60px;
                        &__Img{
                            width: 32px;
                            height: 32px;
                            overflow: hidden;
                            border-radius: 50px;
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            margin-right: 10px;
                            img{
                                object-fit: cover;
                                max-height: 32px;
                            }
                        }
                        &:focus{
                            background-color: $primary-dark;
                            border-color: $primary-dark;
                        }
                        &::after{
                            content: "\e901";
                            font-family: "icomoon"!important;
                            speak: none;
                            font-style: normal;
                            font-weight: 400;
                            -webkit-font-feature-settings: normal;
                            font-feature-settings: normal;
                            font-variant: normal;
                            text-transform: none;
                            line-height: 1;
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                            font-size: 6px;
                            -webkit-margin-start: 10px;
                            margin-left: 10px;
                            -webkit-margin-end: 0;
                            margin-right: 0;
                            -webkit-transform: rotate(0deg);
                            transform: rotate(0deg);
                            transition: all .3s;
                        }
                        &.show{
                            &::after{
                                -webkit-transform: rotate(180deg);
                                transform: rotate(180deg);
                            }
                        }
                        &:before{
                            display: none;
                        }
                        @media screen and (max-width: 450px) {
                            width: 100%;
                            display: flex;
                            justify-content: center;
                        }
                    }
                    @media screen and (max-width: 450px) {
                        flex-wrap: wrap;
                    }
                }
                ul.userDropdown{
                    overflow: initial;
                    li{
                        padding: 0 5px;
                        &:first-child{
                            padding: 0;
                        }
                        &:nth-child(2){
                            i{
                                margin-right: 0;
                            }
                        }
                        .dropdown{
                            min-height: 60px;
                            height: 100%;
                            button{
                                &.dropdown-toggle{
                                    height: 100%;
                                    min-height: 60px;
                                    border: 0 none;
                                    border-radius: 0;
                                    display: flex;
                                    align-items: center;
                                    padding: .375rem 20px;
                                    &::after{
                                        content: '\e901';
                                        font-family: 'icomoon' !important;
                                        speak: none;
                                        font-style: normal;
                                        font-weight: normal;
                                        -webkit-font-feature-settings: normal;
                                        font-feature-settings: normal;
                                        font-variant: normal;
                                        text-transform: none;
                                        line-height: 1;
                                        -webkit-font-smoothing: antialiased;
                                        -moz-osx-font-smoothing: grayscale;
                                        font-size: 6px;
                                        margin-left: 10px;
                                        margin-right: 0;
                                        transform: rotate(0deg);
                                        transition: all 0.3s;
                                    }
                                    &:hover{
                                        background-color: $primary-color;
                                    }
                                    &:focus{
                                        background-color: $primary-dark;
                                    }
                                    .welcomeBack{
                                        @media screen and (max-width: 768px) {
                                            display: none;
                                        }
                                    }
                                }
                                &.show{
                                    &::after{
                                        transform: rotate(180deg);
                                    }
                                }
                            }
                            .dropdown-menu{
                                a{
                                    padding: .25rem 20px;
                                    display: flex;
                                    align-items: center;
                                    text-transform: none;
                                    i{
                                        font-size: 16px;
                                        margin-right: 8px;
                                    }
                                }
                                &.show{
                                    margin: 0;
                                    transform: translate3d(0px, 60px, 0px) !important;
                                    border-radius: 0;
                                    border-bottom-left-radius: .25rem;
                                    border-bottom-right-radius: .25rem;
                                    text-align: left;
                                }
                                button{
                                    &.dropdown-item{
                                        padding: 0;
                                    }
                                }
                            }
                            &.show{
                                button{
                                    &.dropdown-toggle{
                                        background-color: $primary-dark;
                                    }
                                }
                            }
                        }
                        i{
                            margin: 0 !important;
                        }
                        span{
                            &.welcomeBack{
                                text-align: left;
                            }
                        }
                    }
                    @media screen and (max-width: 450px) {
                        justify-content: center;
                        li{
                            &:first-child{
                                width: 100%;
                            }
                        }
                    }
                }
                @media screen and (max-width: 550px) {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                }
            }
            &--rightSide {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                .socialmedia {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    li {
                        overflow: hidden;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: 2px solid #fff;
                        border-radius: 50%;
                        width: 40px;
                        height: 40px;
                        transition: all 0.3s;
                        margin-right: 10px;        
                        &:hover {
                            background-color: $secondary-color;
                            border-color: transparent;
                        }
        
                        a {                    
                            padding: 20px;
                            color: $white;
                            &:hover {
                                color: $white !important;
                            }
                            span{
                                display: none;
                            }
                        }
                    }
                    @media screen and (max-width: 768px) {
                        display: none;
                    }
                }
                .icon-globe {
                    position: absolute;
                    left: 15px;
                    top: calc(50% - 7px);
                    margin-left: 10px;
                    color: $white;
                }
                .btn-primary{
                    background-color: transparent;
                }
                .post-project{
                    min-width: 48px;
                    min-height: 36px;
                    border: 2px solid #fff;
                    border-radius: 50px;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    padding-left: 20px;
                    padding-right: 20px;
                    text-transform: uppercase;
                    margin-left: 10px;
                    font-size: 0.95rem;
                    margin-bottom: 10px;
                    margin-top: 10px;
                    cursor: pointer;
                    span{
                        display: inline-flex;
                        flex-wrap: nowrap;
                        flex: 1 1;
                        min-height: auto;
                        font-size: 0.95rem;
                        font-weight: 500;
                    }
                    i{
                        padding-right: 10px;
                        position: relative;
                        margin-right: 10px;
                        &::after{
                            content: '';
                            border-left: 2px dashed #fff;
                            height: 23px;
                            position: absolute;
                            right: 0px;
                            top: -4px;
                        }
                    }
                }
                .lang-btn{
                    min-width: 48px;
                    min-height: 36px;
                    border: 2px solid #fff;
                    border-radius: 50px;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    padding-left: 20px;
                    padding-right: 20px;
                    text-transform: uppercase;
                    margin-left: 10px;
                    font-size: 0.95rem;
                    margin-bottom: 10px;
                    margin-top: 10px;
                    cursor: pointer;
                    span{
                        display: inline-flex;
                        flex-wrap: nowrap;
                        flex: 1 1;
                        min-height: auto;
                        font-size: 0.95rem;
                        font-weight: 500;
                    }
                    i{
                        padding-left: 10px;
                        position: relative;
                        margin-right: 10px;
                        &::after{
                            content: '';
                            border-right: 2px dashed #fff;
                            height: 23px;
                            position: absolute;
                            left: 0px;
                            top: -4px;
                        }
                    }
                }
                @extend %Button;
                .main__chooseBehavior__posting{
                    margin-right: 10px;
                }
                a{
                    min-height: 36px !important;
                    span{
                        min-height: 36px;
                        &::after{
                            height: 23px !important;
                        }
                    }
                }
                // @media screen and (max-width: 550px) {
                //     display: none;   
                // }
            }
            @media screen and (max-width: 890px) {
                flex-flow: column;
            }
        }
        .btnIcon{
            position: relative;
            min-height: 60px;
            border-radius: 0;
            small{
                position: absolute;
                padding: 0 5px;
                background-color: $secondary-color;
                color: $white;
                border-radius: 50px;
                top: 3px;
                left: 15px;
                right: auto;
                width: 25px;
                height: 25px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    &__nav {
        @media (min-width: 1024px) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-height: 8.1875rem;
        }
        &--leftSide {
            display: flex;
            justify-content: center;
            align-items: center;
            a{
                span{
                    display: inline-block;
                    background-repeat: no-repeat;
                    background-size: contain;
                    &.logoAr{
                        background-image: url('../../imgs/logo-ar.png');
                        width: 243px;
                        height: 60px;
                        @media screen and (max-width: 400px) {
                            width: 162px;
                            height: 40px;
                        }
                        @media screen and (max-width: 335px) {
                            width: 122px;
                            height: 30px;
                        }
                    }
                    &.logoEn{
                        background-image: url('../../imgs/logo.png');
                        width: 277px;
                        height: 60px;
                        @media screen and (max-width: 400px) {
                            width: 185px;
                            height: 40px;
                        }
                        @media screen and (max-width: 335px) {
                            width: 139px;
                            height: 30px;
                        }
                    }
                }
            }
            img {
                max-width: 150px;
                max-height: 70px;
                @media (min-width: 1024px) {
                    max-width: 100%;
                }
            }
        }
        &--rightSide {
            display: none;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            flex-grow: 0.1;
            flex-shrink: 0;
            @media (min-width: 1024px) {
                display: inline-flex !important;
                flex-direction: row;
            }
            li {
                font-weight: 500;
                text-transform: uppercase;
                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-width: 48px;
                    min-height: 48px;
                    color: $white;
                    text-transform: uppercase;
                    position: relative;
                    &::after{
                        content: '';
                        width: 0;
                        height: 3px;
                        background-color: $secondary-color;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        transition: all 0.3s;
                    }
                    &:hover{
                        color: $secondary-color;
                        &::after{
                            width: 100%;
                        }
                    }
                    &.active{
                        color: $secondary-color;
                        cursor: default;
                        &::after{
                            width: 0 !important;
                        }
                    }
                    &:focus{
                        color: $secondary-color;
                    }
                }
            }
        }
        &--mshape {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex: 1;

            padding-top: 0.9375rem;
            padding-bottom: 0.9375rem;
            @media (min-width: 1024px) {
                display: inline-flex;
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }
    hr {
        border-color: white;
        opacity: 0.2;
        margin-top: 0;
        margin-bottom: 0.9375rem;
    }
    .dropdown-menu.show{
        right: 0 !important;
        left: auto !important;
        transform: translate3d(0px, 47px, 0px) !important;
        top: 0;
        .dropdown-item{
            border-radius: 0;
            border: 0 none;
            &.active{
                background-color: $primary-color;
                color: $white;
            }
            &:active{
                background-color: $primary-color;
                color: $white;
            }
            &:hover{
                background-color: $primary-color;
                color: $white;
            }
        }
    }
    @media screen and (max-width: 880px) {
        .Lang{
            &__button{
                &__mobile{
                    display: inline-flex;
                    align-items: center;
                    .btn-primary{
                        padding: 0;
                        border: 0 none;
                        border-radius: 0;
                        margin-right: 10px;
                        background-color: transparent;
                        display: inline-flex;
                        align-items: center;
                        i{
                            margin-left: 8px;
                        }
                        &::before{
                            display: none;
                        }
                        &:hover{
                            background-color: transparent;
                            color: $white;
                        }
                        &:focus{
                            background-color: transparent;
                            color: $white;
                        }
                    }
                }
            }
        }
    }
    @media screen and (min-width: 880px) {
        .Lang{
            &__button{
                &__mobile{
                    display: inline-flex;
                    align-items: center;
                    .btn-primary{
                        display: none;
                    }
                }
            }
        }
    }
}
[dir='rtl'] .header {
    &__topSide {
        &--content {
            &--leftSide {
                ul {
                    li{
                        i{
                            margin-left: 10px;
                            margin-right: 0;
                        }
                    }
                    &.userDropdown{
                        li{
                            span{
                                &.welcomeBack{
                                    text-align: right;
                                }
                            }
                        }
                        .userDropdown__button{
                            padding-left: 20px;
                            padding-right: 1.75rem;
                            &__Img{
                                margin-left: 10px;
                                margin-right: 0;
                            }
                            &::after{
                                margin-right: 10px;
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
            &--rightSide {
                .socialmedia {
                    li {
                        margin-left: 10px;
                        margin-right: 0;
                    }
                }
                .icon-globe {
                    margin-right: 10px;
                    margin-left: 0;
                    right: 15px;
                    left: auto;
                }
                .post-project{
                    margin-left: 10px;
                    margin-right: 0;
                    i{
                        margin-left: 10px;
                        margin-right: 0;
                        padding-left: 10px;
                        padding-right: 0;
                        &::after{
                            left: 0px;
                            right: auto;
                            border-right: 2px dashed #fff;
                            border-left: 0 none;
                        }
                    }
                }
                .lang-btn{
                    margin-right: 10px;
                    margin-left: 0;
                    i{
                        margin-left: 10px;
                        margin-right: 0;
                        padding-right: 10px;
                        padding-left: 0;
                        &::after{
                            right: 0px;
                            left: auto;
                            border-left: 2px dashed #fff;
                            border-right: 0 none;
                        }
                    }
                }
                @extend %Button;
                .main__chooseBehavior__posting{
                    margin-left: 10px;
                    margin-right: 0;
                }
            }
        }
        .btnIcon{
            small{
                right: 15px;
                left: auto;
            }
        }
    }
    &__nav {
        &--rightSide {
            li {
                a {
                    &::after{
                        right: 0;
                        left: auto;
                    }
                }
            }
        }
    }
    .dropdown-menu.show{
        left: 0 !important;
        right: auto !important;
    }
    @media screen and (max-width: 550px) {
        .Lang{
            &__button{
                &__mobile{
                    .btn-primary{
                        margin-left: 10px;
                        margin-right: 0;
                        i{
                            margin-right: 8px;
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }
}
ul.userDropdown__Menu{
    min-width: 273px;
    background-color: $white;
    border-radius: $border-radius-smaller;
    box-shadow: $box-shadow;
    padding-bottom: 5px;
    padding-top: 5px;
    max-width: 380px;
    li{
        a{
            &.dropdown-item{
                padding: 0.5rem 1rem;
                display: inline-flex;
                width: 100%;
                align-items: center;
                border-bottom: 1px solid $section-color;
                white-space: unset;
                i{
                    margin-right: 5px;
                }
                span{
                    &.userImg{
                        width: 35px;
                        height: 35px;
                        border-radius: 50px;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 10px;
                        overflow: hidden;
                        img{
                            min-width: 35px;
                            min-height: 35px;
                            max-height: 35px;
                            object-fit: cover;
                        }
                    }
                }
                .messageContent{
                    width: calc(100% - 45px);
                    p{
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;  
                        overflow: hidden;
                        max-height: 25px;
                        margin-bottom: 0px;
                    }
                }
                .notification{
                    &__Date{
                        color: $p-color;
                        display: flex;
                        align-items: center;
                        width: 100%;
                        font-size: 0.85rem;
                        margin-top: 3px;
                        span{
                            &.icon{
                                margin-right: 8px;
                            }
                        }
                    }
                }
                &.dropdownn-reminder{
                    .messageContent{
                        p{
                            display: block;
                            overflow: visible;
                            max-height: inherit;
                        }
                    }
                    .specialText{
                        font-weight: 500;
                        color: $secondary-color;
                    }
                }
                &:hover{
                    background-color: $primary-color;
                    color: $white;
                    .specialText{
                        font-weight: 500;
                        color: $white;
                    }
                    .notification{
                        &__Date{
                            color: $white;
                        }
                    }
                }
            }
        }
        &:last-of-type{
            a{
                &.dropdown-item{
                    border-bottom: 0;
                }
            }
        }
    }
    @media screen and (max-width: 500px) {
        max-width: 320px;
    }
}
[dir='rtl'] ul.userDropdown__Menu{
    li{
        a{
            &.dropdown-item{
                i{
                    margin-left: 5px;
                    margin-right: 0;
                }
                span{
                    &.userImg{
                        margin-left: 10px;
                        margin-right: 0;
                    }
                }
                .notification{
                    &__Date{
                        span{
                            &.icon{
                                margin-left: 8px;
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

